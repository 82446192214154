import React, { useEffect, useState } from 'react'
import { scroller } from 'react-scroll'
import { navItems, socials } from '~/data'
import { Form, Sales, Button } from '~/components'
import { Link } from 'gatsby'
import cn from 'classnames'
import * as st from '~/assets/styl/Footer.module.styl'

const Footer = () => {
  const [year, setYear] = useState(new Date().getFullYear())

  useEffect(() => {
    const interval = setInterval(() => {
      setYear(new Date().getFullYear())
    }, 60000)

    return () => clearInterval(interval)
  }, [])

  return (
    <footer className={st.core}>
      <div className="container">
        <div className={st.newsletter}>
          <p>
            Receba em seu e-mail nossos <strong>últimos lançamentos</strong>
          </p>
          <Form
            path="subscriber"
            idPrefix="newsletter"
            inputs={[
              {
                name: 'email',
                label: 'E-mail',
                type: 'email',
                placeholder: 'Qual seu e-mail?',
              },
            ]}
            button={<Button type="newsletterButton"></Button>}
          />
        </div>

        <div className={st.sales}>
          <Sales />
        </div>

        <div className={st.socials}>
          <p>
            Acompanhe
            <br />a Alínea
          </p>
          <ul>
            {socials.map(({ title, icon, url }, key) => (
              <li key={key}>
                <Button type="outlined white" to={url} external>
                  <span className={`icon-${icon}`}></span>
                  {title}
                </Button>
              </li>
            ))}
          </ul>
        </div>

        <ul className={st.nav}>
          {navItems.map(({ name, url, icon, subItems }, key) => (
            <li key={key}>
              <Link to={url} className={cn(icon && st.icon)}>
                {icon && <span className={`icon-${icon}`}></span>}
                {name}
              </Link>
              {subItems && (
                <ul>
                  {subItems.map(({ name, url, scrollTo, page }, key) => (
                    <li key={key}>
                      {page === 'external' ? (
                        <a href={url} target="_blank" rel="noreferrer">
                          {name}
                        </a>
                      ) : (
                        <Link
                          to={url}
                          onClick={() =>
                            scrollTo &&
                            scroller.scrollTo(scrollTo, {
                              smooth: true,
                              duration: 500,
                            })
                          }
                        >
                          {name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>

        <div className={st.location}>
          <div className={st.policy}>
            <span>Alínea Urbanismo - All rights reserved {year}.</span>
            <span className={st.policylink}>
              <Link to="/politica-de-privacidade/">
                Política de privacidade
              </Link>
            </span>
          </div>
          <div>
            <span>Administrativo: 48 99173-1754 / 48 3381-7000</span>
            <span>
              Av. Leoberto Leal, 389 - Sala 01 - 1º andar - Barreiros - São José
              / SC - CEP 88117-001
            </span>
            Razão Social: Alínea Participações Ltda.
            <br />
            CNPJ: 07.946.146/0001-73
          </div>
        </div>

        <div className={st.tikiBlock}>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://www.tiki.com.br" target="_blank">
            Tiki
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
